html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
  -moz-user-select:none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}